import { combineReducers, configureStore } from '@reduxjs/toolkit'
import editProductReducer from './editProducts/editProductsSlice'
import cartProductReducer from './cartProducts/cartProductsSlice'
import appsReducer from './apps/AppsSlice'
const rootReducer = combineReducers({
edit: editProductReducer,
cart: cartProductReducer,
apps:appsReducer
  
  })


export const store = configureStore({
  reducer: rootReducer
})

