import React, { useState } from "react";
import axios from "../api/axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
  });
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    setSending(true);


    try {
      await axios.post(
        '/api/contact',
        formData,
      );

  
      setSending(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone: "",
      });
      setMessage("Your Message was sent successfully")
      setTimeout(() => {
        setMessage("")
      }, 6000);
    } catch (error) {
      setSending(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone: "",
      });
      console.error(error.message);
    }
  };

  const handleInoutChange = (e) => {
    const { id, value } = e.target;

    const updateForm = { ...formData, [id]: value };
    setFormData(updateForm);
  };

  return (
    <div className="py-[70px] text-lg md:text-[40px] px-4  bg-[#F5F5F5]">
      <h1 className="text-center mb-[10px] font-semibold">Contact Us</h1>
      <p className="text-center text-[#717171] text-lg mb-[50px]">
        Any question or remarks? Just write us a message!
      </p>
      <p className="text-sm text-center text-green-600 my-1"><strong>{message}</strong></p>
      <div className="grid grid-cols-1 md:grid-cols-12 bg-white min-h-[90dvh] rounded-xl p-4 container mx-auto">
        <div className=" bg-[black] rounded-xl col-span-5 p-10 relative z-0">
          <h1 className="text-white font-semibold">Contact Us</h1>
          <p className="text-[#C9C9C9] text-lg mt-[6px]">
            We are glad to hear from you
          </p>
          <div className="absolute -z-10 hidden lg:block right-0 bottom-0 w-fit">
            <svg
              width="208"
              height="209"
              viewBox="0 0 208 209"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="162.5" cy="160.5" r="134.5" fill="#1A1A1A" />
              <circle
                cx="69"
                cy="69"
                r="69"
                fill="#484848"
                fill-opacity="0.5"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-wrap   flex-row gap-2 items-center order-1 md:order-2 col-span-7">
          <div className="px-6 h-full w-full">
            <form onSubmit={sendEmail} className="text-sm mt-4 md:mt-0 h-full">
            <div className="grid grid-cols-1 lg:grid-cols-2   gap-4">
  <div className="flex flex-col gap-2">
    <label htmlFor="firstName">First Name</label>
    <input
      onChange={(e) => handleInoutChange(e)}
      type="text"
      className="p-2 bg-transparent border-b border-black focus:outline-none"
      id="firstName"
      required
      placeholder="First Name"
      value={formData.firstName}
      name="firstName"
    />
  </div>
  <div className="flex flex-col gap-2">
    <label htmlFor="lastName">Last Name</label>
    <input
      onChange={(e) => handleInoutChange(e)}
      type="text"
      className="p-2 bg-transparent border-b border-black focus:outline-none"
      id="lastName"
      required
      placeholder="Last Name"
      value={formData.lastName}
      name="lastName"
    />
  </div>
  <div className="flex flex-col gap-2">
    <label htmlFor="email">Email</label>
    <input
      onChange={(e) => handleInoutChange(e)}
      type="email"
      className="p-2 bg-transparent border-b border-black focus:outline-none"
      id="email"
      required
      placeholder="Email address"
      value={formData.email}
      name="email"
    />
  </div>
  <div className="flex flex-col gap-2">
    <label htmlFor="phone">Phone Number</label>
    <input
      onChange={(e) => handleInoutChange(e)}
      type="tel"
      className="p-2 bg-transparent border-b border-black focus:outline-none"
      id="phone"
      required
      placeholder="+1 012 3456 789"
      value={formData.phone}
      name="phone"
    />
  </div>
  <div className="flex flex-col gap-2">
    <label htmlFor="message">Message</label>
    <textarea
      onChange={(e) => handleInoutChange(e)}
      className="p-2 bg-transparent border-b border-black focus:outline-none"
      id="message"
      required
      placeholder="How can we help?"
      value={formData.message}
      name="message"
    />
  </div>
</div>

              <input
                type="submit"
                value={sending ? "Sending" : "Send Message"}
                disabled={sending}
                className={`  ${
                  sending ? "opacity-35" : "opacity-100"
                } bg-black cursor-pointer w-fit text-white py-[15px] px-12 rounded-lg mt-[45px] hover:bg-slate-900 transition-all block float-right`}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
