import { createSlice } from '@reduxjs/toolkit'

const initialState = {
apps: [],
searchValue: '',
TitleFilterArr: [],
childrenFilterArr: [],
filteredApps: []
}

const AppsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    SET_APPS(state , action){
        state.apps = action.payload.apps;
        state.filteredApps = action.payload.apps;
    },
    SET_SEARCH_VALUE(state, action) {
      state.searchValue = action.payload;
    },
    MAIN_TITLE_FILTER(state, action) {
      state.TitleFilterArr = action.payload;
    },
    CHILDREN_FILTER(state, action) {
      state.childrenFilterArr = action.payload;
    },
  }
});

export const {SET_APPS , SET_SEARCH_VALUE , MAIN_TITLE_FILTER , CHILDREN_FILTER} = AppsSlice.actions
export const selectApps = (state) => state.apps.apps;
export const selectFilteredApps = (state) => state.apps.filteredApps;
export const selectTitleFilterArr = (state) => state.apps.TitleFilterArr;
export const selectChildrenFilterArr= (state) => state.apps.childrenFilterArr;
export const selectSearchValue= (state) => state.apps.searchValue;

export default AppsSlice.reducer