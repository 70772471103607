import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SkeletonLoader from "../components/SkeletonLoader";
import whyus from '../assets/shoulder-shot-engineer-working-with-architectural-plans-cad-software-desktop-computer-designer-using-arhitecture-blueprints-buildings-working-overtime-creating-studying_482257-13413.avif';
import image2 from '../assets/back-view-male-engineer-drawing-mechanical-prototype-using-cad-software-living-room-girlfriend-background_482257-31247.avif';
import image3 from '../assets/machinery-developer-manufacturing-industrial-product-cad-engineering-software-developing-advanced-engine-component-creating-technical-machine-industry-production-with-pc-interface_482257-50386.avif';
import image4 from '../assets/young-game-developer-using-modern-software-design-interface_482257-23400.avif';
import ourmission from '../assets/goals-target-aspiration-perforated-paper-bullseye_53876-71203.webp';
import aboutus from '../assets/abouus.webp';

const AboutUs = () => {
  const [imagesLoaded, setImagesLoaded] = useState({
    main: false,
    whyus: false,
    image2: false,
    image3: false,
    image4: false,
    ourmission: false,
  });

  const handleImageLoad = (imageName) => {
    setImagesLoaded(prev => ({ ...prev, [imageName]: true }));
  };

  const ImageCard = ({ src, loadedName, title, description }) => (
    <div className="bg-[#fbf8f8] max-w-full mx-auto shadow-xl rounded-md overflow-hidden">
      <img 
        src={src} 
        loading="lazy" 
        className={`h-[200px] w-full object-fill `} 
        alt={title} 
      />
      <div className="p-5">
        <h2 className="text-2xl font-semibold mb-2">{title}</h2>
        <p className="text-lg">{description}</p>
      </div>
    </div>
  );

  return (
    <div className="">
      <div className="flex flex-col bg-[#e6e3e3]">
        <div className="bg-[black] p-5 md:p-0 relative md:min-h-[366px] text-white">
          <div className="container mx-auto px-3 flex-col md:flex-row flex items-center justify-between w-full">
            <div>
              <h1 className="text-[poppins] mb-[30px] text-[48px] font-semibold">
                About Us
              </h1>
              <p className="text-[20px]">
                Welcome to Autodesk Supplier, <br/> your trusted independent provider of official Autodesk products.
              </p>
              <Link to="/products" className="bg-black rounded-[5px] text-base mt-4 block w-fit border-2 border-white px-4 py-1">
                Explore products
              </Link>
            </div>
            <img 
              alt="Autodesk Supplier" 
              width="550" 
              src={aboutus}
              height="auto" 
              className={`max-w-full mt-4 md:mt-0 w-[550px] opacity-65 h-auto`}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6 p-4 container mx-auto my-24">
          {/* Render ImageCard component for each card */}
          <ImageCard 
            src={whyus} 
            loadedName="whyus" 
            title="Subsidiary of Sertic.nl" 
            description="A well-established name in the tech industry, Autodesk Supplier upholds the same commitment to quality, reliability, and exceptional customer service."
          />
          {/* Repeat for other images */}
          <ImageCard 
            src={image2} 
            loadedName="image2" 
            title="Engineer at Work" 
            description="Our expert engineers use cutting-edge tools to create innovative solutions for your business."
          />
          <ImageCard 
            src={image3} 
            loadedName="image3" 
            title="CAD Development" 
            description="We specialize in advanced CAD development to streamline your industrial processes."
          />
          <ImageCard 
            src={image4} 
            loadedName="image4" 
            title="Software Design" 
            description="Our software design services provide modern solutions to meet your business needs."
          />
        </div>
        <div className="px-4">
          <div className="container mx-auto flex items-center bg-[white] p-6 shadow-xl rounded-lg mb-24 gap-4 flex-col-reverse md:flex-row">
            <img 
              loading="lazy" 
              src={ourmission}
              className={`w-[500px] max-w-full rounded-md `} 
              alt="Our Mission" 
            />
            <div>
              <h1 className="text-[poppins] mb-[30px] text-[48px] font-semibold">
                Our Mission
              </h1>
              <p>
                At Autodesk Supplier, we are dedicated to empowering individuals and small businesses by providing Autodesk products at half the price. We believe that access to industry-leading design and engineering tools should be affordable for everyone, helping to foster innovation, creativity, and growth. By making these essential resources more accessible, we aim to support the success of both budding creators and established professionals in achieving their goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
