import axios from 'axios';
// const BASE_URL = 'https://auto-desk-api.vercel.app/'
const BASE_URL = 'https://auto-desk-api-rosy.vercel.app'
// const BASE_URL = 'http://localhost:5001'



export default axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true'
    },
});



export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true'
    },
    withCredentials: true,
    
});