import { createSlice } from '@reduxjs/toolkit'

const initialState = {
show: false,
id: null
}

const editProductsSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    SET_SHOW_EDIT_PRODUCTS(state , action){
        state.show = true;
        console.log(action.payload);
        state.id = action.payload.id
    },
    SET_HIDE_EDIT_PRODUCTS(state , action){
        state.show = false;
    }
  }
});

export const {SET_SHOW_EDIT_PRODUCTS , SET_HIDE_EDIT_PRODUCTS} = editProductsSlice.actions
export const showSelect = (state) => state.edit.show
export const idSelect = (state) => state.edit.id

export default editProductsSlice.reducer