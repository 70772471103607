import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SkeletonLoader from "./SkeletonLoader"; // Make sure to import or create this component
import img from '../assets/advanced-product-construction-industrial-software-with-computer-laptop-using-creative-manufactory-bl.webp'
const Solutions = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className='flex justify-center mt-[120px] pb-16 mx-3'>
      <div className='grid grid-cols-1 md:grid-cols-2 w-[1150px] md:min-h-[377px] gap-5 items-center justify-center rounded-2xl shadow-2xl'>
        <div>
          <div className='flex flex-col items-center pt-[93px] pb-[71px] px-[69px] max-w-full'>
            <div className='max-w-full'>
              <h1 className='text-[20px] lg:text-[48px] leading-tight w-[489px] max-w-full font-medium font-Cairo'>
                Streamline Your Projects with Autodesk Supplier
              </h1>
              <p className='self-start text-[#374151] text-[17px] my-[20px]'>
                Get the tools you need to plan, design, and build with confidence. Discover how Autodesk's Design and Make platform can help your team collaborate seamlessly from concept to completion.
              </p>
              <Link to='/products' className='bg-blue-700 text-white py-[10px] px-5 rounded-lg hover:bg-blue-600 transition-all block w-fit'>
                Explore our catalogue
              </Link>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center h-full'>
          <img 
            loading='lazy' 
            className={`h-full object-cover`} 
            alt="Autodesk"
src={img}
/>
        </div>
      </div>
    </div>
  )
}

export default Solutions;