import React from 'react'
import video from '../assets/autodesk_3 min (1).mp4'
const Hero = () => {
  return (
    <div >
    <div className='grid grid-cols-1 md:grid-cols-2 text-white bg-black overflow-hidden gap-5 items-center justify-center min-h-[40vh] sm:min-h-screen  shadow-lg hero-panner'>
    <video  autoPlay playsInline muted loop id="myVideo">
  <source 
  src="https://firebasestorage.googleapis.com/v0/b/auto-desk-c47f7.appspot.com/o/images%2FA40F0E56-4FAF-4079-BEE6-C9776D197B89.mov?alt=media&token=be98ea2f-4587-46ea-ba3f-ee8cbd267509" type="video/mp4"/>
  {/* src="https://firebasestorage.googleapis.com/v0/b/auto-desk-c47f7.appspot.com/o/images%2Fautodesk_3%20min%20(1)%20(2).mp4?alt=media&token=0c909355-4930-47c9-b85b-cee01f933634" type="video/mp4"/> */}
</video>
        {/* <div  >
            <div className='flex flex-col items-center max-w-full'>
                <div className='max-w-full p-3'>

                <h1 className='  text-[35px] md:text-[40px] lg:text-[72px] leading-tight w-[489px] max-w-full font-medium font-Cairo '>Unleash the Power of Visual Excellence.</h1>
                <p className='self-start text-[13px] md:text-[17px] my-3'>Design & Make with Autodesk: Discover stories, insights, and innovations</p>
                <Link to='/Apps' className='bg-blue-700 text-[14px] md:text-base py-[10px] px-5 rounded-lg my-4 hover:bg-blue-600 transition-all block w-fit'>
                    See our Programs
                </Link>
                </div>
            </div>
        </div> */}
      
    </div>
    </div>
  )
}

export default Hero