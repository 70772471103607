import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
// import { useNavigate , redirect } from 'react-router-dom'
import { storage } from "../Firebase/config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { IoMdClose } from "react-icons/io";
import {
  SET_HIDE_EDIT_PRODUCTS,
  idSelect,
} from "../redux/editProducts/editProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { SET_APPS } from "../redux/apps/AppsSlice";
import Select from 'react-select';

const EditProducts = () => {
  const id = useSelector(idSelect);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const imageRef = useRef();
  const [discountError, setDiscountError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageTempUrl, setTemImageUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgProgress, setImgProgress] = useState(0);
  const [groupedOptions, setGroupedOptions] = useState([])
  // const [fetchedData, setFetchedData] = useState([])
  // const navigate = useNavigate()
  useEffect(() => {
    const handleGetProduct = async (id) => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    handleGetProduct(id);
  }, [id]);

  const handleImageUpload = () => {
    imageRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const desertRef = ref(storage, `${formData.imageUrl}`);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
    const file = event.target.files[0];
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      setLoading(true);
      reader.onloadend = () => {
        // reader.result contains the data URL
        const imageDataURL = reader.result;

        // Now you can use imageDataURL as a temporary URL
        setTemImageUrl(imageDataURL);
        // Perform other actions, e.g., send the data URL to the server
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);

      const storageRef = ref(storage, `images/${Date.now()}${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",

        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setImgProgress(progress);
        },
        (error) => {
          setLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData({ ...formData, imageUrl: downloadURL });
          });
        }
      );
      setLoading(false);
    }
  };

  const handleFetchProducts = async () => {
    try {
      const response = await axios.get("/api/products");
      dispatch(
        SET_APPS({
          apps: response.data,
        })
      );
      // setSpinner(false);
    } catch (error) {
      // setSpinner(false);
    }
  };





  const MainCategoryOptions = [
    { value: 'Architecture, Engineering & Construction', label: 'Architecture, Engineering & Construction' },
    { value: 'Product Design & Manufacturing', label: 'Product Design & Manufacturing' },
    { value: 'Media & Entertainment', label: 'Media & Entertainment' },
    { value: 'Workflows', label: 'Workflows' },
    { value: 'Operating system', label: 'Operating system' },
  ];
  
  const SubcategoryOptions = {
    "Architecture, Engineering & Construction": [
      { value: 'Architecture', label: 'Architecture' },
      { value: 'Structural design', label: 'Structural design' },
      { value: 'MEP design', label: 'MEP design' },
      { value: 'Construction', label: 'Construction' },
      { value: 'Infrastructure', label: 'Infrastructure' },
    ],
    'Product Design & Manufacturing': [
      { value: 'Product design & engineering', label: 'Product design & engineering' },
      { value: 'Simulation & analysis', label: 'Simulation & analysis' },
      { value: 'Manufacturing & production', label: 'Manufacturing & production' },
    ],
    'Media & Entertainment': [
      { value: 'Film & VFX', label: 'Film & VFX' },
      { value: 'Gaming & VR', label: 'Gaming & VR' }

    ],
  'Workflows' : [
  { label: "3D modeling", value: "3D modeling" },
  { label: "Additive manufacturing", value: "Additive manufacturing" },
  { label: "Additive manufacturing optimization", value: "Additive manufacturing optimization" },
  { label: "Administration", value: "Administration" },
  { label: "Animation", value: "Animation" },
  { label: "Bid development", value: "Bid development" },
  { label: "Bid management", value: "Bid management" },
  { label: "CNC/CAM programming", value: "CNC/CAM programming" },
  { label: "Collaboration & control", value: "Collaboration & control" },
  { label: "Composite", value: "Composite" },
  { label: "Concept design", value: "Concept design" },
  { label: "Construction management", value: "Construction management" },
  { label: "Design", value: "Design" },
  { label: "Design collaboration", value: "Design collaboration" },
  { label: "Design development", value: "Design development" },
  { label: "Detail design", value: "Detail design" },
  { label: "Digital factory", value: "Digital factory" },
  { label: "Documentation", value: "Documentation" },
  { label: "Dynamic & motion analysis", value: "Dynamic & motion analysis" },
  { label: "Electrode design & manufacture", value: "Electrode design & manufacture" },
  { label: "Engineering design", value: "Engineering design" },
  { label: "Estimation", value: "Estimation" },
  { label: "Fabrication", value: "Fabrication" },
  { label: "Field collaboration", value: "Field collaboration" },
  { label: "Fluid flow analysis", value: "Fluid flow analysis" },
  { label: "Generative design", value: "Generative design" },
  { label: "Hybrid manufacturing", value: "Hybrid manufacturing" },
  { label: "Industrial robots", value: "Industrial robots" },
  { label: "Inspection & metrology", value: "Inspection & metrology" },
  { label: "Model coordination", value: "Model coordination" },
  { label: "Model simulation", value: "Model simulation" },
  { label: "Modeling for manufacture", value: "Modeling for manufacture" },
  { label: "Molding simulation & process", value: "Molding simulation & process" },
  { label: "Nesting", value: "Nesting" },
  { label: "Operations", value: "Operations" },
  { label: "Preconstruction", value: "Preconstruction" },
  { label: "Prototyping", value: "Prototyping" },
  { label: "Quantification", value: "Quantification" },
  { label: "Reality capture", value: "Reality capture" },
  { label: "Rendering", value: "Rendering" },
  { label: "Renovation", value: "Renovation" },
  { label: "Risk management", value: "Risk management" },
  { label: "Schematic design", value: "Schematic design" },
  { label: "Simulation", value: "Simulation" },
  { label: "Site project management", value: "Site project management" },
  { label: "Structural analysis", value: "Structural analysis" },
  { label: "Surfacing", value: "Surfacing" },
  { label: "Thermal analysis", value: "Thermal analysis" },
  { label: "Visualization", value: "Visualization" },
  { label: "VR", value: "VR" }
],

    
    'Operating system': [
      { value: 'Windows', label: 'Windows' },
      { value: 'Mac', label: 'Mac' },
      { value: 'Linux', label: 'Linux' },
      { value: 'Cloud service', label: 'Cloud service' },
      // { value: 'mobile app', label: 'mobile app' },
      // { value: 'Online app', label: 'Online app' },

    ],
  };

  useEffect(() => {
    // Update subcategory options based on selected main categories
    const selectedMainCats = formData.mainCategories;
    const subcatOptions = selectedMainCats?.flatMap(cat => {
      const options = SubcategoryOptions[cat];
      return options ? { label: cat, options } : [];
    });
    // setFormData(prevData => ({ ...prevData, subCats: subcatOptions }));
    setGroupedOptions(subcatOptions);
  }, [formData.mainCategories]);



















  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const total = formData.discountPrice
    ? formData.price - formData.discountPrice
    : formData.price;
    try {
      const response = await axios.put(
        `/api/products/update/${id}`,
        JSON.stringify({ ...formData, total }),

        {
          headers: { "Content-Type": " application/json" },
        }
      );
      setLoading(false);
      setFormData("");
      setErrorMess("");
      handleFetchProducts();
      setSuccessMessage(`${formData.productname} updated successfully`);
      setTemImageUrl("");
      setImageFile("");
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
      dispatch(SET_HIDE_EDIT_PRODUCTS());
      // navigate("/Admin/all-products" , {scroll: true})
    } catch (error) {
      setErrorMess(error.response?.data?.message);
      setLoading(false);
      const desertRef = ref(storage, `${formData.imageUrl}`);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
      setFormData({});
    }
  };

  const hanfleFormChange = (e) => {
    const { id, value } = e.target;
    if (id === "stock-in" || id === "stock-out") {
      setFormData({ ...formData, stock: value === "true" ? true : false });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  useEffect(() => {
    const discountprice = parseFloat(formData.discountPrice);
    const price = parseFloat(formData.price);
    const total = discountprice - price;
    if (price <= 0) {
      setPriceError("Procuct Price must be positive value");
      return;
    } else {
      setPriceError("");
    }
    if (discountprice < 0) {
      setDiscountError("Discount Price can not be negative value");
      return;
    }
    if (total >= 0) {
      setDiscountError("Discount Price must be less than the product price");
      return;
    }
    setPriceError("");
    setDiscountError("");
  }, [formData.discountPrice, formData.price]);



  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );











  return (
    <div className="flex justify-center items-center fixed w-screen top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 h-[100%] bg-[#1c191994] z-40 p-6">
      <div className="flex flex-col items-center bg-slate-50 w-[80%] p-5 md:p-8 rounded-lg my-14 max-h-full editapp-mobile overflow-y-scroll">
        <div
          className="self-end cursor-pointer hover:text-red-500"
          onClick={() => dispatch(SET_HIDE_EDIT_PRODUCTS())}
        >
          <IoMdClose size={20} />
        </div>

        <div className="sm:mt-12 w-full">
          <p className="text-red-600 text-[15px] font-semibold">{errorMess}</p>
          <p className="text-green-500 text-[20px] mb-5 font-semibold">
            {successMessage}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-10 gap-7">
              <div className="w-full sm:w-[266px] max-w-full">
                <div className="flex flex-col gap-3 mb-4">
                  <label htmlFor="productname">Product Name:</label>
                  <input
                    className="py-1 px-2 border-2  bg-white rounded-lg focus:outline-none "
                    type="text"
                    id="productname"
                    value={formData.productname}
                    placeholder="Adobe Acrobat ... "
                    required
                    onChange={(e) => hanfleFormChange(e)}
                  />
                </div>
                <div>
                  <div className="flex flex-col gap-3 mb-4 w-[200px] h-[200px] rounded-2xl border-2 relative image group">
                    <input
                      className="py-1 px-2 border-2 rounded-lg  focus:outline-none "
                      ref={imageRef}
                      hidden
                      type="file"
                      id="image"
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                    <img
                      src={imageTempUrl ? imageTempUrl : formData.imageUrl}
                      alt="product"
                      className={` ${
                        !imageTempUrl && !formData.imageUrl ? "hidden" : "block"
                      }   rounded-2xl w-full h-full`}
                    />
                    <button
                      type="button"
                      onClick={handleImageUpload}
                      className={` ${
                        imageTempUrl || formData.imageUrl ? "hidden" : "block"
                      } bg-slate-100 opacity-70 h-full w-full absolute group-hover:block `}
                    >
                      Upload Image
                    </button>
                  </div>

                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div
                      className={`bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full ${
                        imgProgress ? "block" : "hidden"
                      } `}
                      style={{ width: `${imgProgress}%` }}
                    >
                      {" "}
                      {parseInt(imgProgress)} %
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-full sm:w-[266px] max-w-full">
                <div className="flex items-center gap-3">

                
                <div className="flex flex-col gap-3 mb-4">
                <label htmlFor="productname">Product Price <span className="text-xs text-red-600">/1-year</span>:</label>
                <input
                    onChange={(e) => hanfleFormChange(e)}
                    className="py-1 px-2 border-2 bg-white rounded-lg focus:outline-none "
                    type="number"
                    id="price"
                    placeholder="$"
                    min={1}
                    required
                    value={formData.price}
                  />
                  <p className="text-[12px] text-red-600">{priceError}</p>
                </div>
                <div className="flex flex-col gap-3 mb-4">
                  <label htmlFor="productname">Discount Price <span className="text-xs text-red-600">/1-year</span>:</label>
                  <input
                    onChange={(e) => hanfleFormChange(e)}
                    className="py-1 px-2 bg-white border-2 rounded-lg focus:outline-none "
                    type="number"
                    id="discountPrice"
                    placeholder="$"
                    min={1}
                    value={formData.discountPrice}
                  />
                  <p className="text-[12px] text-red-600 max-w-full ">
                    {discountError}
                  </p>
                </div>
                </div>

                <div className="flex items-center gap-3">

                
                {/* <div className="flex flex-col gap-3 mb-4">
                <label htmlFor="productname">Product Price <span className="text-xs text-red-600">/1-year</span>:</label>
                <input
                    onChange={(e) => hanfleFormChange(e)}
                    className="py-1 px-2 border-2 bg-white rounded-lg focus:outline-none "
                    type="number"
                    id="price-1-year"
                    placeholder="$"
                    min={1}
                    required
                    value={formData["price-1-year"]}
                  />
                  <p className="text-[12px] text-red-600">{priceError}</p>
                </div> */}


                {/* <div className="flex flex-col gap-3 mb-4">
                  <label htmlFor="productname">Discount Price <span className="text-xs text-red-600">/1-year</span>:</label>
                  <input
                    onChange={(e) => hanfleFormChange(e)}
                    className="py-1 px-2 bg-white border-2 rounded-lg focus:outline-none "
                    type="number"
                    id="discountPrice-1-year"
                    placeholder="$"
                    min={1}
                    value={formData["discountPrice-year"]}
                  />
                  <p className="text-[12px] text-red-600 max-w-full ">
                    {discountError}
                  </p>
                </div> */}








                </div>










             
                <div className="flex flex-col gap-3 mb-4">
                    <label htmlFor="productname">Filter Category:</label>
                    {/* <input
                      onChange={(e) => hanfleFormChange(e)}
                      className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                      type="text"
                      id="filterCategory"
                      placeholder="3D modeling"
                      required
                    value={formData.filterCategory}
                    /> */}
                   <Select
        isMulti
        options={MainCategoryOptions}
        value={formData?.mainCategories?.map(category => ({ value: category, label: category }))}
        onChange={(selected) => {
          const selectedValues = selected.map(option => option.value);
          setFormData(prevData => ({ ...prevData, mainCategories: selectedValues }));
        
        }}
        placeholder="Select main categories"
      />
                  <Select
                value={formData?.subcategories?.map(category => ({ value: category, label: category }))}
        isMulti
        options={groupedOptions}
        onChange={(selected) => {
          console.log(selected);
          const selectedValues = selected.map(option => option.value);
          setFormData(prevData => ({ ...prevData, subcategories: selectedValues }));
      
        }}
        placeholder="Select subcategories"
        formatGroupLabel={formatGroupLabel}
      />   
                  </div>
                <p htmlFor="stock">Stock Availability:</p>
                <div className="flex flex-col gap-1 mt-1">
                  <div className="flex items-center gap-1">
                    <input
                      disabled={imgProgress > 0 && imgProgress < 100}
                      onChange={(e) => hanfleFormChange(e)}
                      id="stock-in"
                      type="radio"
                      value={true}
                      checked={formData.stock === true}
                      name="stock"
                      className="w-4 h-4  cursor-pointer text-blue-600 bg-gray-100 border-gray-300   dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="text-[13px]">In stock</label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      disabled={imgProgress > 0 && imgProgress < 100}
                      onChange={(e) => hanfleFormChange(e)}
                      id="stock-out"
                      type="radio"
                      value={false}
                      name="stock"
                      checked={formData.stock === false}
                      className="w-4 h-4 cursor-pointer text-red-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="text-[13px]">Out of stock</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 mb-4">
              <label htmlFor="productname">Description:</label>
              <textarea
                onChange={(e) => hanfleFormChange(e)}
                className="py-1 px-2 border-2 bg-white rounded-lg focus:outline-none "
                type="text"
                id="description"
                placeholder="Explain the product ... "
                required
                value={formData.description}
              />
            </div>

            {/* <input className='bg-blue-700 text-white py-[10px] px-5 rounded-lg my-5 disabled:bg-blue-100 disabled:cursor-not-allowed cursor-pointer hover:bg-blue-600 transition-all block w-fit' type='submit' disabled={formData.discountError || formData.priceError} value='Add Product'  /> */}
            <button
              disabled={
                discountError ||
                priceError ||
                loading ||
                (!imageFile && !formData.imageUrl)
              }
              type="submit"
              className={`  ${
                discountError ||
                priceError ||
                (!imageFile && !formData.imageUrl)
                  ? "opacity-40"
                  : ""
              }   text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center`}
            >
              {loading && (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}

              {loading ? "Loading..." : "Update Product"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProducts;
