import { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
// import { useNavigate , redirect } from 'react-router-dom'
import { storage } from "../../Firebase/config";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import ProductCard from "../../components/ProductCard";
// import { redirect, useNavigate } from 'react-router-dom'
// import useAuth from '../../hooks/useAuth'
import { Popconfirm } from "antd";
import {
  SET_SHOW_EDIT_PRODUCTS,
  showSelect,
} from "../../redux/editProducts/editProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

const Apps = () => {
  const show = useSelector(showSelect);
  const [add, setAdd] = useState(true);
  const [formData, setFormData] = useState({mainCategories:[] , subcategories:[]});
  const imageRef = useRef();
  const [discountError, setDiscountError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageTempUrl, setTemImageUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [imgProgress, setImgProgress] = useState(0);
  const [groupedOptions, setGroupedOptions] = useState([])
  // const [open, setOpen] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const showPopconfirm = () => {
  //   setOpen(true);
  // };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openStates, setOpenStates] = useState();






  const MainCategoryOptions = [
    { value: 'Architecture, Engineering & Construction', label: 'Architecture, Engineering & Construction' },
    { value: 'Product Design & Manufacturing', label: 'Product Design & Manufacturing' },
    { value: 'Media & Entertainment', label: 'Media & Entertainment' },
    { value: 'Workflows', label: 'Workflows' },
    { value: 'Operating system', label: 'Operating system' },
  ];
  
  const SubcategoryOptions = {
    "Architecture, Engineering & Construction": [
      { value: 'Architecture', label: 'Architecture' },
      { value: 'Structural design', label: 'Structural design' },
      { value: 'MEP design', label: 'MEP design' },
      { value: 'Construction', label: 'Construction' },
      { value: 'Infrastructure', label: 'Infrastructure' },
    ],
    'Product Design & Manufacturing': [
      { value: 'Product design & engineering', label: 'Product design & engineering' },
      { value: 'Simulation & analysis', label: 'Simulation & analysis' },
      { value: 'Manufacturing & production', label: 'Manufacturing & production' },
    ],
    'Media & Entertainment': [
      { value: 'Film & VFX', label: 'Film & VFX' },
      { value: 'Gaming & VR', label: 'Gaming & VR' }

    ],
 'Workflows' : [
  { label: "3D modeling", value: "3D modeling" },
  { label: "Additive manufacturing", value: "Additive manufacturing" },
  { label: "Additive manufacturing optimization", value: "Additive manufacturing optimization" },
  { label: "Administration", value: "Administration" },
  { label: "Animation", value: "Animation" },
  { label: "Bid development", value: "Bid development" },
  { label: "Bid management", value: "Bid management" },
  { label: "CNC/CAM programming", value: "CNC/CAM programming" },
  { label: "Collaboration & control", value: "Collaboration & control" },
  { label: "Composite", value: "Composite" },
  { label: "Concept design", value: "Concept design" },
  { label: "Construction management", value: "Construction management" },
  { label: "Design", value: "Design" },
  { label: "Design collaboration", value: "Design collaboration" },
  { label: "Design development", value: "Design development" },
  { label: "Detail design", value: "Detail design" },
  { label: "Digital factory", value: "Digital factory" },
  { label: "Documentation", value: "Documentation" },
  { label: "Dynamic & motion analysis", value: "Dynamic & motion analysis" },
  { label: "Electrode design & manufacture", value: "Electrode design & manufacture" },
  { label: "Engineering design", value: "Engineering design" },
  { label: "Estimation", value: "Estimation" },
  { label: "Fabrication", value: "Fabrication" },
  { label: "Field collaboration", value: "Field collaboration" },
  { label: "Fluid flow analysis", value: "Fluid flow analysis" },
  { label: "Generative design", value: "Generative design" },
  { label: "Hybrid manufacturing", value: "Hybrid manufacturing" },
  { label: "Industrial robots", value: "Industrial robots" },
  { label: "Inspection & metrology", value: "Inspection & metrology" },
  { label: "Model coordination", value: "Model coordination" },
  { label: "Model simulation", value: "Model simulation" },
  { label: "Modeling for manufacture", value: "Modeling for manufacture" },
  { label: "Molding simulation & process", value: "Molding simulation & process" },
  { label: "Nesting", value: "Nesting" },
  { label: "Operations", value: "Operations" },
  { label: "Preconstruction", value: "Preconstruction" },
  { label: "Prototyping", value: "Prototyping" },
  { label: "Quantification", value: "Quantification" },
  { label: "Reality capture", value: "Reality capture" },
  { label: "Rendering", value: "Rendering" },
  { label: "Renovation", value: "Renovation" },
  { label: "Risk management", value: "Risk management" },
  { label: "Schematic design", value: "Schematic design" },
  { label: "Simulation", value: "Simulation" },
  { label: "Site project management", value: "Site project management" },
  { label: "Structural analysis", value: "Structural analysis" },
  { label: "Surfacing", value: "Surfacing" },
  { label: "Thermal analysis", value: "Thermal analysis" },
  { label: "Visualization", value: "Visualization" },
  { label: "VR", value: "VR" }
],

    'Operating system': [
      { value: 'Windows', label: 'Windows' },
      { value: 'Mac', label: 'Mac' },
      { value: 'Linux', label: 'Linux' },
      { value: 'Cloud service', label: 'Cloud service' },
      // { value: 'mobile app', label: 'mobile app' },
      // { value: 'Online app', label: 'Online app' },

    ],
  };

  useEffect(() => {
    // Update subcategory options based on selected main categories
    const selectedMainCats = formData.mainCategories;
    const subcatOptions = selectedMainCats?.flatMap(cat => {
      const options = SubcategoryOptions[cat];
      return options ? { label: cat, options } : [];
    });
    // setFormData(prevData => ({ ...prevData, subCats: subcatOptions }));
    setGroupedOptions(subcatOptions);
  }, [formData.mainCategories]);

















  const showPopconfirm = (productId) => {
    setOpenStates(productId);
  };

  const handleOk = (productId) => {
    setConfirmLoading(true);

    // Simulate asynchronous operation
    setTimeout(() => {
      setOpenStates(false);
      setConfirmLoading(false);
      handleDelete(productId);
    }, 2000);
  };
  const handleCancel = (productId) => {
    setOpenStates(false);
  };
  // const navigate = useNavigate()
  // const { auth } = useAuth();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(fetchedData);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
console.log(newItems)
    setFetchedData(newItems);


    try {
       await axios.post(
        `/api/products/updateOrder`,
        JSON.stringify({ newItems }),

        {
          headers: { "Content-Type": " application/json" },
        }
      );
      
      console.log(`updated successfully`);
  
    } catch (error) {
    
      console.error("Not updated");
    }

  };

  const handleImageUpload = () => {
    imageRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // reader.result contains the data URL
        const imageDataURL = reader.result;

        // Now you can use imageDataURL as a temporary URL
        setTemImageUrl(imageDataURL);
        // Perform other actions, e.g., send the data URL to the server
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);

      const storageRef = ref(storage, `images/${Date.now()}${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setImgProgress(parseInt(progress));
          // switch (snapshot.state) {
          //   case 'paused':
          //     break;
          //   case 'running':
          //     break;
          //     default:
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData({ ...formData, imageUrl: downloadURL });
          });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const total = formData.discountPrice
      ? formData.price - formData.discountPrice
      : formData.price;
    console.log(formData);
    try {
      await axios.post(
        "/api/products",
        JSON.stringify({ ...formData, total }),

        {
          headers: { "Content-Type": " application/json" },
        }
      );
      // const data = response.data;
      const allinputs = document.querySelectorAll("input");
      allinputs.forEach((input) => {
        input.value = "";
      });
      const textarea = document.querySelectorAll("textarea");
      textarea.forEach((text) => {
        text.value = "";
      });
      setLoading(false);
      setFormData({});
      setErrorMess("");
      setSuccessMessage(`${formData.productname} added successfully`);
      setTemImageUrl("");
      setImageFile("");
      setImgProgress(0);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setErrorMess(error.response?.data?.message);
      setLoading(false);
      const desertRef = ref(storage, `images/${imageFile.name}`);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
      setFormData({});
    }
  };

  const hanfleFormChange = (e) => {
    const { id, value } = e.target;

    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    handleFetchProducts();
  }, [show]);

  const handleFetchProducts = async () => {
    setImageFile("");
    setTemImageUrl("");
    setFormData("");
    setImgProgress(0);
    setAdd(false);
    setSpinner(true);

    try {
      const response = await axios.get("/api/products");
      setFetchedData(response.data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  };
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  useEffect(() => {
    const discountprice = parseFloat(formData.discountPrice);
    const price = parseFloat(formData.price);
    const total = discountprice - price;
    if (price <= 0) {
      setPriceError("Product Price must be positive value");
      return;
    } else {
      setPriceError("");
    }
    if (discountprice < 0) {
      setDiscountError("Discount Price can not be negative value");
      return;
    }
    if (total >= 0) {
      setDiscountError("Discount Price must be less than the product price");
      return;
    }
    setPriceError("");
    setDiscountError("");
  }, [formData.discountPrice, formData.price]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/api/products/delete/${id}`);
      console.log(response.data);
      handleFetchProducts();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="flex p-2 gap-2 ring-1 rounded-xl w-full text-center">
        <p
          onClick={() => setAdd(true)}
          className={`${
            add ? "bg-slate-200" : "bg-slate-50 "
          } w-1/2 p-2 rounded-lg cursor-pointer text-[12px] sm:text-[16px]  hover:bg-slate-200 transition-colors duration-300 `}
        >
          Add Product
        </p>
        <p
          onClick={handleFetchProducts}
          className={`${
            add ? "bg-slate-50 " : "bg-slate-200"
          } w-1/2 p-2 rounded-lg cursor-pointer text-[12px] sm:text-[16px] hover:bg-slate-200 transition-colors duration-300  `}
        >
          View Products
        </p>
      </div>
      <div className="w-full ">
        {add ? (
            <div className="max-w-5xl mx-auto p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Add New Product</h2>
            
            {errorMess && (
              <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
                {errorMess}
              </div>
            )}
            
            {successMessage && (
              <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-6">
                {successMessage}
              </div>
            )}
      
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-6">
                  <div>
                    <label htmlFor="productname" className="block text-sm font-medium text-gray-700 mb-1">
                      Product Name
                    </label>
                    <input
                      type="text"
                      id="productname"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                      placeholder="Revit, Fusion 360..."
                      onChange={hanfleFormChange}
                      required
                    />
                  </div>
      
                  <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Product Image
  </label>
  <div className="border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center space-y-4">
    {imageTempUrl ? (
      <img src={imageTempUrl} alt="Preview" className="max-h-32 rounded-lg" />
    ) : (
      <p className="text-gray-500">No image selected</p>
    )}
    
    <input
      ref={imageRef}
      type="file"
      id="image"
      hidden
      accept="image/*"
      onChange={handleFileInputChange}
    />
    
    <button
      type="button"
      onClick={handleImageUpload}
      className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
    >
      {imageTempUrl ? "Change Image" : "Upload Image"}
    </button>
  </div>

  {imgProgress > 0 && (
    <div className="mt-2">
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div className="bg-blue-600 h-2.5 rounded-full transition-all" style={{ width: `${imgProgress}%` }} />
      </div>
      <p className="text-sm text-gray-500 mt-1">{imgProgress}% uploaded</p>
    </div>
  )}
</div>

                </div>
      
                {/* Right Column */}
                <div className="space-y-6">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Product Price <span className="text-xs text-red-500">/1-year</span>
                      </label>
                      <input
                        type="number"
                        id="price"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="€"
                        min={1}
                        onChange={hanfleFormChange}
                        required
                      />
                      {priceError && <p className="mt-1 text-sm text-red-500">{priceError}</p>}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Discount Price <span className="text-xs text-red-500">/1-year</span>
                      </label>
                      <input
                        type="number"
                        id="discountPrice"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="€"
                        min={0}
                        onChange={hanfleFormChange}
                      />
                      {discountError && <p className="mt-1 text-sm text-red-500">{discountError}</p>}
                    </div>
                  </div>
      
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Main Categories
                    </label>
                    <Select
                      isMulti
                      options={MainCategoryOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select main categories"
                      onChange={(selected) => {
                        const selectedValues = selected.map(option => option.value);
                        setFormData(prevData => ({ ...prevData, mainCategories: selectedValues }));
                      }}
                    />
                  </div>
      
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Subcategories
                    </label>
                    <Select
                      isMulti
                      options={groupedOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select subcategories"
                      formatGroupLabel={formatGroupLabel}
                      onChange={(selected) => {
                        const selectedValues = selected.map(option => option.value);
                        setFormData(prevData => ({ ...prevData, subcategories: selectedValues }));
                      }}
                    />
                  </div>
      
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Stock Availability
                    </label>
                    <div className="space-y-2">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="stock"
                          value={true}
                          defaultChecked
                          onChange={hanfleFormChange}
                          className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-sm text-gray-700">In stock</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="stock"
                          value={false}
                          onChange={hanfleFormChange}
                          className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-sm text-gray-700">Out of stock</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
      
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  id="description"
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Explain the product..."
                  onChange={hanfleFormChange}
                  required
                />
              </div>
      
              <button
                type="submit"
                disabled={discountError || priceError || loading || !imageFile}
                className={`w-full sm:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg font-medium
                  ${(discountError || priceError || !imageFile) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}
                  transition-colors duration-200 flex items-center justify-center`}
              >
                {loading && (
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                )}
                {loading ? 'Adding Product...' : 'Add Product'}
              </button>
            </form>
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd} className="flex justify-center  items-center bg-slate-100 my-3 w-full p-5">
            {spinner ? (
              <div role="status" className="flex items-center justify-center py-8">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Droppable droppableId="droppable" className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full gap-2">
      {(provided) => (
        <div {...provided.droppableProps} className="p-3 " ref={provided.innerRef}>
          {fetchedData.length ? (
            fetchedData.map((product, index) => (
              <Draggable key={product._id} draggableId={product._id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flex flex-col gap-2 max-w-full mb-3"
                  >
                    <ProductCard isAdmin product={product} stock={product.stock} />
                    <div className="flex justify-evenly items-center ">
                        <button
                          type="button"
                          className="text-white py-1 px-2 text-[12px] bg-blue-700 rounded-lg hover:bg-blue-600"
                          onClick={() =>
                            dispatch(
                              SET_SHOW_EDIT_PRODUCTS({
                                id: product._id,
                              })
                            )
                          }
                        >
                          Edit
                        </button>
                        <Popconfirm
                          title={product.productname}
                          description="Delete this product?"
                          open={openStates === product._id}
                          onConfirm={() => handleOk(product._id)}
                          okButtonProps={{
                            loading: confirmLoading,
                            style: {
                              backgroundColor: "#1890ff",
                              color: "#fff",
                              border: "none",
                            }, // Add your custom styles here
                          }}
                          onCancel={handleCancel}
                        >
                          <button
                            type="button"
                            onClick={() => showPopconfirm(product._id)}
                            className="text-white py-1 px-2 text-[12px] bg-red-700 rounded-lg hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </Popconfirm>
                      </div>
                  </div>
                )}
              </Draggable>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center col-span-full">
              <h1 className="text-[18px] sm:text-[40px]">No products to view</h1>
              <p>Try Adding Some</p>
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
            )}
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default Apps;
