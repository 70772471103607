import img1 from '../assets/Fusion 360.gif'
import img2 from '../assets/Civil-3D---Project-Explorer.gif'
import img3 from '../assets/Fabrication-CADmep.gif'
import img4 from '../assets/Civil-3D-road-with-elevation.gif'

const programs = ["https://firebasestorage.googleapis.com/v0/b/auto-desk-c47f7.appspot.com/o/images%2F1723990864276Flame.gif?alt=media&token=024b0532-8a56-4db1-937a-eeffc8169ac5",	"https://firebasestorage.googleapis.com/v0/b/auto-desk-c47f7.appspot.com/o/images%2F1723991506318Point-Layout.gif?alt=media&token=148c02f0-a673-4c9d-9f61-e9a7d3fc1389" ,img1, img2, img3, img4]

const Programs = () => {
  return (
    <div className='py-24 bg-gray-50'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='text-center'>
          <p className='mb-6 text-lg leading-7 text-gray-600'>
            All Autodesk products available through our platform come 
            with a mandatory 1-year subscription. This ensures you receive 
            continuous access to the latest updates, features, and official 
            support throughout the year, keeping your software fully optimized and up-to-date.
          </p>
          <p className='text-xl font-semibold text-[#303a4a] mb-12'>
            Unlock boundless creative possibilities with Autodesk's unparalleled suite of tools,
            <br className='hidden md:block'/>
            from AutoCAD and Revit to Fusion 360, revolutionizing design, engineering, and entertainment industries.        
          </p> 
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-6 justify-items-center">
            {programs.map((program, i) => (
              <img 
                key={i} 
                src={program} 
                loading='lazy'
                className='size-16 object-contain transition duration-300 ease-in-out transform hover:scale-110' 
                alt={`Autodesk program ${i + 1}`} 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Programs