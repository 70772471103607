import useLocalstorage from "./useLocalstorage"




const useToggle = (key , initialValue) => {
    const [value, setValue] = useLocalstorage(key , initialValue);


  const toggle = () =>{
    setValue(prev => {
        return !prev;
    })

  }

  



  return [value , toggle];
}

export default useToggle