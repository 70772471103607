import { IoMdClose } from "react-icons/io";
import {
  HIDE_CART,
  SET_CART,
  selectCart,
} from "../redux/cartProducts/cartProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { selectApps } from "../redux/apps/AppsSlice";
import { Link } from "react-router-dom";

const Cart = ({showCart}) => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const apps = useSelector(selectApps);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);

  // useEffect(() => {
  //   const appsCopy = apps;
  //   const updatedApps = appsCopy?.filter((app) =>
  //     cart.some((cartItem) => app.productname === cartItem.productname)
  //   );

  //   if (updatedApps.length) {
  //     handleTotalCartPrice(updatedApps);
  //     dispatch(SET_CART({ updatedApps }));
  //   }
  // }, [apps]);
  const handleTotalCartPrice = useCallback(() => {
    const TotalCartPrice = cart?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total;
      }, 0);
      
      setCartTotalPrice(TotalCartPrice);
      },[cart]);
  useEffect(() => {
    handleTotalCartPrice()
    }, [cart, handleTotalCartPrice])
    
    

  const handleCartAdjust = (product) => {
    const cartCopy = cart;
    const updatedCart = cartCopy.filter((item) => item.productname !== product);
    handleTotalCartPrice(updatedCart);
    dispatch(SET_CART({ updatedApps: updatedCart }));
  };

  return (
    <div className={`fixed ${showCart ? "opacity-100 flex" : "opacity-0 hidden"} w-full  z-30 top-0 bg-[#1a1a1aa1] h-screen justify-end`}>

    <div className={`bg-white fixed w-full md:w-[640px] h-full flex  flex-col transition-all ease-in-out right-0 duration-300 ${showCart ? "translate-x-0" : "translate-x-full"}`}>
        <div className="flex justify-between items-center p-6 border-b">
          <h1 className="text-2xl font-semibold">Cart</h1>
          <IoMdClose
            size={24}
            className="cursor-pointer"
            onClick={() => dispatch(HIDE_CART())}
          />
        </div>

        <div className="flex-grow overflow-y-auto px-6">
          <h2 className="text-xl font-semibold my-4">New subscriptions</h2>
          {cart.length ? (
            cart.map((item) => {
              if (item.stock === false) {
                handleCartAdjust(item.productname);
              }
              return (
                <div
                  key={item.productname}
                  className="flex justify-between items-start py-6 border-b"
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={item.imageUrl}
                      className="w-12 h-12"
                      alt={item.productname}
                    />
                    <div>
                      <h3 className="font-semibold">{item.productname}</h3>
                      <p className="text-gray-600">Subscription</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-end gap-2">
                    <span className="font-semibold">€{item.total.toFixed(2)}</span>
                    <button 
                      onClick={() => handleCartAdjust(item.productname)}
                      className="text-gray-500 hover:underline text-sm"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-8">
              <p className="text-xl mb-4">No products to show. Try adding some</p>
              <Link
                onClick={() => dispatch(HIDE_CART())}
                className="text-blue-600"
                to="/products"
              >
                Check our Apps
              </Link>
            </div>
          )}
        </div>

        {cart.length > 0 && (
          <div className="border-t p-6">
            <div className="flex justify-between items-center mb-4">
              <span className="text-xl">SUBTOTAL</span>
              <span className="text-xl font-semibold">
                €{cartTotalPrice.toFixed(2)}
              </span>
            </div>
            <p className="text-gray-600 mb-4">All prices included TAX <span className="text-red-600">21%</span></p>
            <Link
              to="/checkout"
              onClick={() => dispatch(HIDE_CART())}
              className="bg-black text-white w-full py-4 rounded flex justify-center items-center text-lg font-semibold"
            >
              Check out
            </Link>
            <p className="text-gray-600 text-sm mt-4">
              Credit and debit cards, PayPal, Pay by Bank and direct debit (ACH) accepted.
            </p>
          </div>
        )}
      </div>
      </div>

  );
};

export default Cart;