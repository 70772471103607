import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import axios from "../api/axios";
import { BackTop, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CHILDREN_FILTER, SET_APPS, SET_SEARCH_VALUE, selectChildrenFilterArr, selectFilteredApps, selectSearchValue, selectTitleFilterArr } from "../redux/apps/AppsSlice";
import FilterOptionsSection from "../components/FilterOptionsSection";
import { LiaFilterSolid } from "react-icons/lia";
import { IoIosClose } from "react-icons/io";

const Apps = () => {
  const dispatch = useDispatch();
  const [fetchedData, setFetchedData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false)
  const filteredApps = useSelector(selectFilteredApps);
  const TitleFilterArr = useSelector(selectTitleFilterArr);
  const childrenFilterArr = useSelector(selectChildrenFilterArr);
  const searchValue = useSelector(selectSearchValue);
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 3000) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    handleFetchProducts();
  }, []);

  const handleFetchProducts = async () => {
    let filterOptions = [];
    setIsLoading(true);
    try {
      const response = await axios.get("/api/products");
      response?.data?.forEach((element) => {
        filterOptions.push(element.filterCategory);
      });
      // const filterOptionsSet = new Set(filterOptions);
      setFilterOptions(filterOptions);
      setFetchedData(response.data);
      setDataCopy(response.data);
      dispatch(
        SET_APPS({
          apps: response.data,
        })
      );
      setIsLoading(false);
      // setSpinner(false);
    } catch (error) {
      // setSpinner(false);
    }
  };

  useEffect(() => {
  dispatch(SET_SEARCH_VALUE(search)); 
    },
    [dispatch, search]
  );


useEffect(() => {
  const handleFilterByCat = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/filterProducts", { 
        mainCategories: TitleFilterArr, 
        subcategories: childrenFilterArr,
        searchText: searchValue,
      });
      setFetchedData(response.data)
      // Process response data as needed
      setIsLoading(false);
      // setSpinner(false);
    } catch (error) {
      // Handle error appropriately
      // setSpinner(false);
      setIsLoading(false);

    }
  };

  handleFilterByCat();
}, [TitleFilterArr, childrenFilterArr , searchValue]);

  const handleFilterByChildre = (checkedChildrenArr) => {
    setIsLoading(true);
    // if(!TitleFilterArr && !childrenFilterArr) handleFetchProducts()
     dispatch(CHILDREN_FILTER(checkedChildrenArr)); // Filter list based on search value

    setIsLoading(false);
  };







  return (
    <div className="flex flex-col gap-9 relative py-6 px-3 w-full container mx-auto">
      <h1 className="text-[40px]">Autodesk products</h1>
      <hr />
      <div className="grid grid-cols-12 gap-y-9 lg:gap-y-0 md:gap-x-9  justify-center">
      <div
  title="click to apply filter"
  className="cursor-pointer  animate-pulse xl:hidden rounded-md col-span-12 flex items-center gap-2 p-3 border border-black   "
  onClick={() => setShowFilter(true)}
>
  <LiaFilterSolid size={20}/>
  <p>filters</p>
</div>
        <div className={`xl:col-span-4 xl:static xl:block xl:bg-white xl:p-0 w-full flex items-center justify-center z-10 px-3 fixed h-full transition-all duration-300 ease-in-out bg-[#0b040478] p-7 ${showFilter ? 'left-0 ': 'left-[-10000px]' } top-[0] `}>
          <div className="bg-white  p-5 sticky top-0 w-full max-h-[600px] overflow-y-auto">
            <div className="flex justify-between items-center ">
          <h2 className="text-3xl">Filter By</h2>
              <div className="block xl:hidden" onClick={() => setShowFilter(false)}>
<IoIosClose size={30}/>
              </div>
            </div>
          <FilterOptionsSection
            fetchedData={fetchedData}
            />
            </div>
        </div>
        <div className="flex flex-col gap-9  min-h-[45rem] col-span-12 xl:col-span-8">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="MotionBuilder, AutoCAD, Revit..."
              required
            />
            {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
          </div>

          {/* <div className="grid grid-cols-1  md:grid-cols-2  rounded-lg xl:grid-cols-4 w-full gap-3 bg-slate-200 p-3"> */}
            {isLoading ? (
              <div className="flex items-center justify-center w-full"><Spin size="large" /></div>
            ) : fetchedData.length ? (
              <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3   rounded-lg  w-full gap-3">
             { fetchedData.map((product, i) => (
                // <Skeleton
                //   key={i}
                //   loading={loading}
                //   className="flex flex-col gap-2 max-w-full"
                // >
                  <ProductCard
                  key={product.productname}
                    product={product}
                    addtocart={true}
                    stock={product.stock}
                  />
                // </Skeleton>
              ))}
            </div>
            
            )
            : (
              // <Skeleton avatar title={false} loading={true} active />
<p>No Products to show.</p>
            )}
        </div>
      </div>
        {showDiv && 
      <div>
    <BackTop
    visibilityHeight={3000}
  />
  </div>
  }
    </div>
  );
};

export default Apps;
