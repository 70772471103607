import { Skeleton } from "antd";
import { SET_CART } from "../redux/cartProducts/cartProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCart } from "../redux/cartProducts/cartProductsSlice";
import { useEffect, useState } from "react";
import PlansDropDown from "./PlansDropDown";
import { IoLogoApple } from "react-icons/io";
import { DiWindows } from "react-icons/di";
import { CiCloudOn } from "react-icons/ci";
import { FaLinux } from "react-icons/fa6";

const ProductCard = ({ product, addtocart, stock , isAdmin }) => {
  const {
    productname,
    imageUrl,
    price,
    discountPrice,
    description,
    total,
    plans,
    subcategories,
  } = product;

const availablePlatforms = [

 'Windows',
   'Mac', 
   'Linux', 
   'Cloud service', 
  // 'mobile app', 
  //  'Online app'
]
const Linux = () => {
  return <svg id="dhig--icons--linux" class="cmp-icon MuiSvgIcon-root DhigSvgIcon DhigSvgIcon--size--medium inline ">
  <use xlinkHref="#dhig--icons--miscellaneous--linux"></use>
</svg>
}

  const platforms = subcategories.filter((cat) => {
    if (availablePlatforms.includes(cat)) return cat;

    return false;
  });
console.log(platforms);

  const platformIcons = (e) => {
    const platformsHasIcon = {
      Mac: <IoLogoApple size={20} />,
    Windows: <DiWindows size={20} />,
    "Cloud service": <CiCloudOn size={20} />,
    "Linux": <FaLinux size={20} />,
    }
  if(platformsHasIcon[e]) return platformsHasIcon[e]

  return e

  };
  const cart = useSelector(selectCart);
  const [cartNames, setCartNames] = useState([]);
  const currentPlan = Object.entries(plans).find(
    ([key, val]) => val === true
  )?.[0];
  const [currentSelectedPlan, setcurrentSelectedPlan] = useState(currentPlan);
  const dispatch = useDispatch();

  useEffect(() => {
    const names = cart.map((cart) => cart.productname);
    setCartNames(names);
  }, [cart]);

  const getPlanDisplayedText = {
    Monthly: "month",
    "1 year": "year",
    "3 year": "3 years (pay annually)",
  };

  return (
    <div
      className={`flex flex-col gap-3 border-2 p-3 group overflow-y-hidden card ${
        !stock ? "bg-[#ffcccc]" : "bg-[#F9F9F9]"
      }   h-[450px] max-w-full`}
    >
      {product ? (
        <div className="h-full flex flex-col gap-3">
          <img
            src={imageUrl}
            alt={productname}
            className="w-[55px] h-[55px] border border-[#ccc] rounded-lg hover:scale-[1.3] duration-150 ease-in-out "
          />
          <p className="font-semibold">{productname}</p>
          <div className="flex items-center gap-1 ">
            {discountPrice && (
              <h1
                className={` text-[20px] ${
                  discountPrice ? "line-through text-slate-950 bg-lime-300" : ""
                }`}
              >
                <span>€</span>
                {price?.toLocaleString('en-US')}
              </h1>
            )}
            {/* {discountPrice ?  */}
            <h1 className="text-[20px] ">
              <span>€</span>
              {total.toLocaleString('en-US')}
              <span className="text-xs">
                /{getPlanDisplayedText[currentSelectedPlan || "1 year"]}
              </span>
            </h1>
            {/* : ""} */}
          </div>
          {!isAdmin && 

            <div className="group-hover:block hidden w-full my-3">
              <PlansDropDown
                plans={plans}
                currentSelectedPlan={currentSelectedPlan}
                setcurrentSelectedPlan={setcurrentSelectedPlan}
              />
  
  
  
  
  
  {addtocart && stock && (
              <button
                disabled={cartNames.includes(productname)}
                onClick={() =>
                  dispatch(
                    SET_CART({
                      product,
                      currentSelectedPlan
                    })
                  )
                }
                className={`${
                  cartNames.includes(productname)
                    ? "bg-green-700"
                    : "bg-black hover:bg-slate-900"
                } bg-black text-white text-sm font-semibold py-[5px] px-[8px] text-left rounded-md my-4   transition-all block w-full`}
                type="button"
              >
                {cartNames.includes(productname)
                  ? "Added Successfully"
                  : "ADD TO CART"}
              </button>
            )}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
              {platforms.length > 0 && (
                <p className="text-xs w-full flex items-center mt-2">
                  platform:
                  {platforms?.map((e) => (
                    <span className="mr-[3px]">{platformIcons(e)}</span>
                  ))}
                </p>
              )}
            </div>

          }

          <div className="max-w-full">
            <p className={` max-h-full  ${!isAdmin ? 'group-hover:hidden' : ''} max-w-full`}>{description}</p>
          </div>

        
          {!stock && (
            <p
              className={`bg-red-600 text-white text-[12px] font-semibold py-[5px] px-[6px] rounded-3xl my-4 self-end  transition-all block w-fit`}
            >
              Out of stock
            </p>
          )}
        </div>
      ) : (
        <Skeleton paragraph={{ rows: 4 }} active />
      )}
    </div>
  );
};

export default ProductCard;
