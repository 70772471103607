import { useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import { CiSquareMinus } from "react-icons/ci";

const CustomCheckBox = ({ label , isMainCategory , setIsExpanded , isExpanded , elementsNumber , onChange , checked}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="checkbox-wrapper flex items-center justify-between">
      <label className="flex items-center gap-5 flex-1">
        <input 
        className="size-7"
        type="checkbox" 
        checked={checked} 
        onChange={onChange}
        />
        <span>{label}</span>
        {elementsNumber}
      </label>
      {isMainCategory && 
      <div 
    onClick={isMainCategory ? setIsExpanded : null}
  className="cursor-pointer">
        {isExpanded  ? 
<CiSquareMinus size={28}/>
:
<CiSquarePlus size={28}/>
      }
      </div>
}
    </div>
  );
};
export default CustomCheckBox;