import React, { useState } from 'react';

const PlansDropDown = ({ plans , currentSelectedPlan, setcurrentSelectedPlan }) => {
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown visibility
  };
const sortOptions = (a ,b) =>{

    if(a === 'Monthly') return -1

    return 0
}
  return (
    <div className="relative inline-block"> {/* Parent needs relative positioning */}
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown} // Toggle dropdown on button click
        className="flex items-center justify-between border-b border-black hover:shadow-lg"
        type="button"
      >
        {currentSelectedPlan}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isDropdownOpen && (
        <div
          id="dropdown"
          className="absolute z-10 bg-white   shadow w-44 dark:bg-gray-700 mt-2"
          style={{ top: '100%' }} // Positioning below the button
        >
          <ul className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {Object.keys(plans).sort(sortOptions).map((plan) => (
              <li
                key={plan}
                className="bg-inherit p-1 cursor-pointer hover:bg-[#eee] pl-[22px] pt-[6px] pb-1 text-base"
                onClick={() => {
                  setcurrentSelectedPlan(plan);
                  setIsDropdownOpen(false); // Close dropdown after selecting a plan
                }}
              >
                {plan}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PlansDropDown;
