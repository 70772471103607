import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Support from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Apps from "./pages/Apps";
import HomePage from "./pages/HomePage";
import React from "react";
// import Footer from "./components/Footer";
import Admin from "./pages/admin/Admin";
import Unauthorized from "./components/Unauthorized";
import PersistLogin from "./components/PersistLogin";
import Register from "./pages/Register";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import Missing from "./components/Missing";
import Cart from "./components/Cart";
import { useDispatch, useSelector } from "react-redux";
import { selectshowCart } from "./redux/cartProducts/cartProductsSlice";
import { useEffect } from "react";
import axios from "./api/axios";
import { SET_APPS } from "./redux/apps/AppsSlice";
import { CheckoutForm } from "./pages/CheckoutForm";
import StripeReturn from "./pages/StripeReturn";
import ScrollToTop from "./components/ScrollToTop";
const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
  };
const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  const dispatch = useDispatch();
const location = useLocation();
const { pathname } = location;

  useEffect(() => {
    handleFetchProducts();
  }, []);

  const handleFetchProducts = async () => {
    try {
      const response = await axios.get("/api/products");
      dispatch(
        SET_APPS({
          apps: response.data,
        })
      );
      // setSpinner(false);
    } catch (error) {
      // setSpinner(false);
    }
  };

  const showCart = useSelector(selectshowCart);
  useEffect(() => {
   if(showCart) document.body.style.overflow="hidden"
else document.body.style.overflow="auto"
  }, [showCart])

  return (
    <div className="App font-Cairo">
            <ScrollToTop />
     {pathname.includes('checkout') || pathname.includes('return') ? 
      ""
      :
      <Navbar />
    }
      <Cart showCart={showCart} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<Support />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* <Route
          path="/Adobe_Master_Collection_2024"
          element={<MasterCollection />}
        /> */}
        <Route path="/products" element={<Apps />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<StripeReturn />} />

        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<HomePage />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="Admin/*" element={<Admin />} />
          </Route>
        </Route>

        <Route path="*" element={<Missing />} />
      </Routes>
      {/* {pathname.includes('checkout') || pathname.includes('return') || pathname.includes('login') ?  */}
      {/* "" */}
      {/* : */}
      <Footer />
    {/* } */}
    </div>
  );
}

export default App;
