import React, { useRef, useState, useEffect } from "react";
import axios from "../api/axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";

const LOGIN_URL = "/api/auth";
 
const Login = () => {
  const { setAuth, auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, resetUser, userAttributeObj] = useInput("user", "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [check, toggleCheck] = useToggle("persist", false);

  useEffect(() => {
    userRef.current.focus();
  }, [auth]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleViewPassword = () => {
    const passwordInput = document.getElementById("password");
    const passwordInputType = passwordInput.getAttribute("type");
    if (passwordInputType === "password")
      return passwordInput.setAttribute("type", "text");
    return passwordInput.setAttribute("type", "password");
  };

  const handleSubmit = async (e) => {
    console.log(user);
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      setAuth({ user, accessToken, roles });
      setPwd("");
      resetUser();
      navigate(from, { replace: true });
    } catch (err) {
      if (err?.response) {
        setErrMsg("No server response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
        console.log(err.message);
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="flex items-center justify-center h-[calc(100vh-56px)] z-40 relative ">      <section className="flex flex-col shadow-xl bg-white  rounded-xl  border border-gray-200 w-full max-w-sm p-6 space-y-4">
        <p
          ref={errRef}
          className={errMsg ? "text-red-600" : "sr-only"}
          aria-live="assertive"
        >
          {errMsg}
        </p>

        <h1 className="font-semibold text-3xl text-center mb-4">Sign In</h1>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              ref={userRef}
              id="username"
              autoComplete="off"
              {...userAttributeObj}
              required
              className="border p-2 rounded-lg focus:outline-none"
              placeholder="Username"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              className="border p-2 rounded-lg focus:outline-none"
              placeholder="Password"
            />
          </div>
          <button
            type="button"
            className="text-xs text-blue-500 hover:underline-offset-1 hover:underline float-right"
            onClick={handleViewPassword}
          >
            View Password
          </button>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="persist"
              onChange={toggleCheck}
              checked={check}
              className="rounded"
            />
            <label htmlFor="persist">Trust This Device</label>
          </div>

          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-lg mt-4 hover:bg-blue-600 transition-all w-full"
            type="submit"
          >
            Sign In
          </button>
        </form>

        <div className="text-center">
          <p>
            Need an Account?{" "}
            <Link to="/register" className="text-blue-700">
              Sign Up
            </Link>
          </p>
        </div>
      </section>
      <div className="absolute right-0  bottom-0 signin-custom-pattern w-52 h-80" />
    </div>
  );
};

export default Login;
