import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { EMPTY_CART } from '../redux/cartProducts/cartProductsSlice';
import { useDispatch } from 'react-redux';
import Realistic from "react-canvas-confetti/dist/presets/realistic";


const StripeReturn = () => {
    const [status, setStatus] = useState(null);
    const [lineItems, setlineItems] = useState('')
    const [sessionData, setSessionData] = useState('')
    const navigate = useNavigate()
  const dispatch = useDispatch()
//   const [conductor, setConductor] = useState();

  const onInit = ({ conductor }) => {
    conductor?.shoot();
  };

    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
      axios.get(`/stripe/session-status?session_id=${sessionId}`)
      .then((response) => {
        const data = response.data;
        setStatus(data.status);
        setlineItems(data.lineItems);
        setSessionData(data.session);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
    }, []);
  
    if (status === 'open') {
      
        navigate('/checkout'  , {replace: true})

      
    }
    if (status === 'expired') {
    
        navigate('/'  , {replace: true})

      
    }



   





  
    if (status === 'complete') {
        dispatch(EMPTY_CART())
      return (
        <section id="success" className="p-6 rounded-md ">

<div className="bg-white text-white w-[600px] shadow-xl mx-auto max-w-full my-8">
      <div className="bg-white w-full"></div>


      {/* Payment details */}
      <div className="bg-white border-l-10 border-r-10 border-gray-200 p-6">
        <div className="flex items-center justify-center mb-8">
          <img src="http://dgtlmrktng.s3.amazonaws.com/go/emails/generic-email-template/tick.png" alt="GO" className="w-12" />
        </div>
        <div className="">
          <h1 className="text-[30px] font-bold text-[#ff6a13] text-center">Payment received</h1>
          <p className="text-gray-700">Hi <span className="text-blue-600 font-medium">{sessionData.customer_details.name}</span></p>
        </div>
        <div className="my-2 text-gray-700">
          <p>Your transaction was successful!</p>
          <p className="mb-2">An invoice email will be sent to your mail shortly</p>
          <p>
            <strong>Payment Details:</strong><br />
            Amount: <span className="text-blue-600 font-medium">{(sessionData.amount_total / 100).toFixed(2)} {sessionData.currency}</span>
          </p>
          <div className="mt-6">
            <h1>Products:</h1>
            {lineItems.data.map((item, index) => (
              <div className='text-blue-600 font-medium flex items-center justify-between'  key={index}>
               <p>
                 {item.description} 
                </p>
               <p className='text-red-600 font-medium'>  
                 {item.amount_total / 100} 
                 <span className='text-black ms-1'>

                    {item.currency} 
                    </span></p>

              </div>
            ))}
          </div>
        </div>
        <hr className="my-6 border-t border-gray-300" />
        <div className="text-center">
          <p className="text-gray-700"><strong>Contact Email: </strong></p>
  <a href="mailto:info@sertic.nl" className="text-blue-600">
    info@sertic.nl
  </a>

        </div>
      </div>

    </div>


        <Realistic onInit={onInit} />

        <button className='underline text-blue-600 block mx-auto text-center' onClick={() => navigate("/" , {replace: true})}>Return to Home page</button>
      </section>
      )
    }
  
    return null;
  }

export default StripeReturn