import { createSlice } from '@reduxjs/toolkit'

const initialState = {
cart : localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [] ,
cartProduct: {},
showCart: false
}

const cartProductsSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
      SET_CART(state, action) {
        console.log(action.payload);
        
        // Create a shallow copy of cartProduct to avoid direct mutation
        const cartProductCopy = { ...action.payload.product };
  console.log(cartProductCopy);
        if (action.payload.updatedApps) {
          state.cart = action.payload.updatedApps;
          localStorage.setItem('cart', JSON.stringify(action.payload.updatedApps));
        } else {
          // Updating the plans object based on the selected plan
          const currentSelectedPlan = action.payload.currentSelectedPlan;
  
          // Make a copy of the plans object and update it immutably
          const updatedPlans = Object.fromEntries(
            Object.entries(cartProductCopy.plans).map(([key]) => [key, key === currentSelectedPlan])
          );
  
          // Now update the copy of cartProduct's plans
          cartProductCopy.plans = updatedPlans;
          cartProductCopy.quantity = 1;
  
          // Push the updated cartProduct into the cart array immutably
          const updatedCart = [...state.cart, cartProductCopy];
  console.log(updatedCart);

          state.cart = updatedCart;
  
          // Save the updated cart to local storage
          localStorage.setItem('cart', JSON.stringify(updatedCart));
        }
      },
      SHOW_CART(state) {
        state.showCart = true;
      },
      HIDE_CART(state) {
        state.showCart = false;
      },
      EMPTY_CART(state) {
        state.cart = [];
        localStorage.removeItem('cart');
      }
    }
  });
  

  
  
 
  

export const {SET_CART , SHOW_CART , HIDE_CART , EMPTY_CART} = cartProductsSlice.actions
export const selectCart = (state) => state.cart.cart 
export const selectshowCart = (state) => state.cart.showCart 
export default cartProductsSlice.reducer