import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import {IoIosMenu} from 'react-icons/io'
import { PiShoppingCartDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_CART, selectCart } from '../redux/cartProducts/cartProductsSlice';
const Navbar = () => {
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
    const [mobileNav, setMobileNav] = useState(false)


    const navLinkStyles = ({ isActive, isPending }) => `
    hover:bg-[#1a1a1a] py-1 px-2  md:rounded-md duration-300 text-black lg:text-white ease-in-out
    ${isPending ? "pending hover:text-blue-800" : ""}
    ${isActive ? "bg-[#1a1a1a] text-white" : "hover:bg-[#1a1a1a]"}
  `;

const handleMobileNav = () => {


  setMobileNav(prev => !prev)
  const body = document.body;
  
    body.classList.toggle('no-scroll');
  

}





  return (
    <div className='bg-black  text-white' >
<div className='  container mx-auto py-[10px]  flex items-center px-3 justify-between '>
    <div className='flex items-center  flex-1'>

    <Link to='/' className=' text-2xl sm:text-3xl font-bold' >
    Autodesk <sub className='text-sm'>Supplier</sub>
    </Link>

    <div className='hidden lg:flex  xl:gap-24  ms-24 justify-between gap-6 text-[14px]  xl:text-[16px]'>
<NavLink  className={navLinkStyles}  to='/'>Home</NavLink>
  <NavLink  className={navLinkStyles} to='/products'>Products</NavLink>
<NavLink  className={navLinkStyles} to='/aboutus'>About us</NavLink>
<NavLink className={navLinkStyles} to='/contact'>Contact</NavLink>
    </div>
    </div>
    <div className=''>
        <button data-collapse-toggle="navbar-mobile" onClick={handleMobileNav} className={`block lg:hidden rounded-lg ${mobileNav ? 'ring ring-gray-200' : ''} `} aria-controls='navbar-mobile' aria-expanded={mobileNav} type='button'>
    <IoIosMenu aria-hidden="true"   size={30} />
        </button>
    <div id='navbar-mobile' onClick={handleMobileNav} className={`flex flex-col fixed h-screen z-[1] bg-[#000000b0] gap-4 border-t-2 shadow-md w-screen top-[0] left-0 p-1 lg:hidden ${mobileNav ? 'flex' : 'hidden'} `}>
      <div 
      
      onClick={handleMobileNav}
      className='bg-white w-3/4 [&>*]:border-b-2 h-full flex flex-col gap-4 [&>*]:py-3 [&>*]:px-4 [&>*]:text-[17px]'>
    <NavLink 
    onClick={handleMobileNav}
    className={navLinkStyles}  to='/'>Home</NavLink>
<NavLink  onClick={handleMobileNav} className={navLinkStyles} to='/products'>Products</NavLink>
<NavLink onClick={handleMobileNav} className={navLinkStyles} to='/aboutus'>About us</NavLink>
<NavLink onClick={handleMobileNav} className={navLinkStyles} to='/contact'>Contact</NavLink>
 
      {/* <NavLink onClick={handleMobileNav} className={({ isActive, isPending }) =>
    isPending ? "pending bg-[#FA0F00] px-2 py-1  hover:bg-slate-400 rounded-xl text-white" : isActive ? "bg-red-400 px-2 py-1 rounded-xl text-red" : " block bg-[#FA0F00] px-2 py-1 sm:px-2 sm:py-1 w-fit  rounded-xl self-center hover:bg-[#ff0001] text-white"
  } to='/contact-us'>Contact Us</NavLink> */}

  </div>
    </div>
    </div>
    <div onClick={() => dispatch(SHOW_CART())} className='me-3 relative cursor-pointer'>
  <PiShoppingCartDuotone size={30}  />
  <p className='text-white bg-red-600 absolute top-0 p-2 text-[12px] w-3 flex items-center justify-center h-3 right-0 rounded-full '>{cart.length}</p>

  </div>
    {/* <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending bg-[#FA0F00] px-6 py-3  hover:bg-slate-400 rounded-xl text-white" : isActive ? "bg-red-400 px-6 py-3 rounded-xl text-red" : "hidden lg:block bg-[#FA0F00] px-3 py-2 sm:px-6 sm:py-3  rounded-xl hover:bg-[#ff0001] text-white"
  } to='/contact-us'>Contact Us</NavLink> */}
</div>
    </div>
  )
}

export default Navbar