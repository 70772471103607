import React, { useEffect } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import EditApps from "./EditApps";
import Home from "./Home";
import { LuUsers } from "react-icons/lu";
import { TbAppsFilled } from "react-icons/tb";
import EditProducts from "../../components/EditProduct";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { showSelect } from "../../redux/editProducts/editProductsSlice";

const Admin = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const showEditProduct = useSelector(showSelect);

  useEffect(() => {
    if (!auth?.accessToken) {
      navigate("/login");
    }
  }, [auth, navigate]);

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="bg-gray-900 text-gray-100 w-64 p-6 flex flex-col">
        <h1 className="text-3xl font-semibold tracking-tight mb-12 text-center text-white">
          Admin
        </h1>
        <nav className="space-y-4">
          <NavLink
            to="/Admin/home"
            className={({ isActive }) =>
              `flex items-center gap-3 px-4 py-2 rounded-lg font-medium text-lg ${
                isActive ? "bg-blue-600" : "hover:bg-gray-800"
              }`
            }
          >
            <LuUsers className="text-2xl" />
            <span>Home</span>
          </NavLink>
          <NavLink
            to="/Admin/apps"
            className={({ isActive }) =>
              `flex items-center gap-3 px-4 py-2 rounded-lg font-medium text-lg ${
                isActive ? "bg-blue-600" : "hover:bg-gray-800"
              }`
            }
          >
            <TbAppsFilled className="text-2xl" />
            <span>Apps</span>
          </NavLink>
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col bg-white overflow-y-auto">
        <header className="px-10 py-6 bg-white shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800">Dashboard</h2>
        </header>
        <main className="flex-1 p-10">
          <Routes>
            <Route path="apps" element={<EditApps />} />
            <Route path="home" element={<Home />} />
          </Routes>
        </main>
      </div>

      {/* Edit Products Overlay */}
      {showEditProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <EditProducts />
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
