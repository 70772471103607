import React, { useRef, useState, useEffect } from "react";
import axios, { axiosPrivate } from "../api/axios";
import { Link } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/api/register";

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axiosPrivate.post(
        REGISTER_URL,
        JSON.stringify({ user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setSuccess(true);
      setUser("");
      setPwd("");
      setMatchPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {success ? (
        <section className="text-center">
          <h1 className="text-3xl font-semibold mb-2">Success!</h1>
          <p>
            <Link to="/login" className="text-blue-700">
              Sign In
            </Link>
          </p>
        </section>
      ) : (
        <section className="flex flex-col items-center shadow-xl rounded-xl border border-gray-200 w-full max-w-sm p-6 space-y-4">
          <p
            ref={errRef}
            className={errMsg ? "text-red-600" : "sr-only"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1 className="text-3xl font-semibold mb-4">Register</h1>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-col space-y-2">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                aria-invalid={!validName}
                className="border p-2 rounded-lg focus:outline-none"
              />
              <p
                className={
                  userFocus && user && !validName
                    ? "block text-sm text-red-600"
                    : "hidden"
                }
              >
                4 to 24 characters. Must begin with a letter. Letters, numbers,
                underscores, hyphens allowed.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={!validPwd}
                className="border p-2 rounded-lg focus:outline-none"
              />
              <p
                className={
                  pwdFocus && !validPwd
                    ? "block text-sm text-red-600"
                    : "hidden"
                }
              >
                8 to 24 characters. Must include uppercase and lowercase
                letters, a number, and a special character.
              </p>
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="confirm_pwd">Confirm Password:</label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={!validMatch}
                className="border p-2 rounded-lg focus:outline-none"
              />
              <p
                className={
                  matchFocus && !validMatch
                    ? "block text-sm text-red-600"
                    : "hidden"
                }
              >
                Must match the first password input field.
              </p>
            </div>

            <button
              type="submit"
              className="bg-blue-700 text-white py-2 px-4 rounded-lg mt-4 hover:bg-blue-600 transition-all w-full"
              disabled={!validName || !validPwd || !validMatch}
            >
              Sign Up
            </button>
          </form>

          <div className="text-center">
            <p>
              Already registered?{" "}
              <Link to="/login" className="text-blue-700">
                Sign In
              </Link>
            </p>
          </div>
        </section>
      )}
    </div>
  );
};

export default Register;
