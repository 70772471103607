import React, { useState } from "react";
import CustomCheckBox from "./CustomCheckBox";
import { CHILDREN_FILTER, MAIN_TITLE_FILTER } from "../redux/apps/AppsSlice";
import { useDispatch } from "react-redux";

const checkBoxData = [
  {
    label: "Architecture, Engineering & Construction",
    checked: false,
    children: [
      { label: "Architecture", checked: false },
      { label: "Structural design", checked: false },
      { label: "MEP design", checked: false },
      { label: "Construction", checked: false },
      { label: "Infrastructure", checked: false },
    ],
  },
  {
    label: "Product Design & Manufacturing",
    checked: false,
    children: [
      { label: "Product design & engineering", checked: false },
      { label: "Simulation & analysis", checked: false },
      { label: "Manufacturing & production", checked: false },
    ],
  },
  {
    label: "Media & Entertainment",
    checked: false,
    children: [
      { label: "Film & VFX", checked: false },
      { label: "Gaming & VR", checked: false },
    ],
  },
  {
    label: "Workflows",
    checked: false,
    children: [
      { label: "3D modeling", checked: false },
      { label: "Additive manufacturing", checked: false },
      { label: "Additive manufacturing optimization", checked: false },
      { label: "Administration", checked: false },
      { label: "Animation", checked: false },
      { label: "Bid development", checked: false },
      { label: "Bid management", checked: false },
      { label: "CNC/CAM programming", checked: false },
      { label: "Collaboration & control", checked: false },
      { label: "Composite", checked: false },
      { label: "Concept design", checked: false },
      { label: "Construction management", checked: false },
      { label: "Design", checked: false },
      { label: "Design collaboration", checked: false },
      { label: "Design development", checked: false },
      { label: "Detail design", checked: false },
      { label: "Digital factory", checked: false },
      { label: "Documentation", checked: false },
      { label: "Dynamic & motion analysis", checked: false },
      { label: "Electrode design & manufacture", checked: false },
      { label: "Engineering design", checked: false },
      { label: "Estimation", checked: false },
      { label: "Fabrication", checked: false },
      { label: "Field collaboration", checked: false },
      { label: "Fluid flow analysis", checked: false },
      { label: "Generative design", checked: false },
      { label: "Hybrid manufacturing", checked: false },
      { label: "Industrial robots", checked: false },
      { label: "Inspection & metrology", checked: false },
      { label: "Model coordination", checked: false },
      { label: "Model simulation", checked: false },
      { label: "Modeling for manufacture", checked: false },
      { label: "Molding simulation & process", checked: false },
      { label: "Nesting", checked: false },
      { label: "Operations", checked: false },
      { label: "Preconstruction", checked: false },
      { label: "Prototyping", checked: false },
      { label: "Quantification", checked: false },
      { label: "Reality capture", checked: false },
      { label: "Rendering", checked: false },
      { label: "Renovation", checked: false },
      { label: "Risk management", checked: false },
      { label: "Schematic design", checked: false },
      { label: "Simulation", checked: false },
      { label: "Site project management", checked: false },
      { label: "Structural analysis", checked: false },
      { label: "Surfacing", checked: false },
      { label: "Thermal analysis", checked: false },
      { label: "Visualization", checked: false },
      { label: "VR", checked: false }
    ],
    
  },
  {
    label: "Operating system",
    checked: false,
    children: [
      { label: 'Windows', checked: false },
  { label: 'Mac', checked: false },
  { label: 'Linux', checked: false },
  { label: 'Cloud service', checked: false },
  // { label: 'mobile app', checked: false },
  // { label: 'Online app', checked: false }
    ],
  },
];

const FilterOptionsSection = ({ fetchedData }) => {
  const [checkBoxState, setCheckBoxState] = useState(checkBoxData);
  const [isExpanded, setIsExpanded] = useState(
    Array(checkBoxData.length).fill(false)
  );
  const dispatch = useDispatch();
  const handleParentChange = (index) => {
    scrollToTop()
    const newCheckBoxState = [...checkBoxState];
    newCheckBoxState[index].checked = !newCheckBoxState[index].checked;
    setCheckBoxState(newCheckBoxState);
    const checked = newCheckBoxState
      .filter((e) => {
        if (e.checked) {
          return e.label;
        } else {
          return null;
        }
      })
      .map((e) => e.label);
    dispatch(MAIN_TITLE_FILTER(checked));
  };

  const handleChildChange = (parentIndex, childIndex) => {
    scrollToTop()
    let checkedChildren = [];
    const newCheckBoxState = [...checkBoxState];
    newCheckBoxState[parentIndex].children[childIndex].checked =
      !newCheckBoxState[parentIndex].children[childIndex].checked;
    setCheckBoxState(newCheckBoxState);
    const checked = newCheckBoxState[parentIndex].children.filter(
      (e) => e.checked
    );
    newCheckBoxState.forEach((element) => {
      element.children.forEach((child) => {
        if (child.checked) checkedChildren.push(child.label);
      });
    });
    dispatch(CHILDREN_FILTER(checkedChildren));
  };
  const toggleExpansion = (index) => {
    const newIsExpanded = [...isExpanded];
    newIsExpanded[index] = !newIsExpanded[index];
    setIsExpanded(newIsExpanded);
  };

  const customStyles = "border-b border-[#ccc] py-6";

  const elementsNumber = (category) => {
    const foundCategory = fetchedData?.filter((elemnt) =>
      elemnt.subcategories?.includes(category)
    );

    return <span>({foundCategory.length})</span>;
  };



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <div>
      {checkBoxState.map((parent, parentIndex) => (
        <div key={parent.label} className={`${customStyles}`}>
          <div className="mb-4 font-semibold text-lg">
            <CustomCheckBox
              label={parent.label}
              checked={parent.checked}
              onChange={() => handleParentChange(parentIndex)}
              isMainCategory={true}
              isExpanded={isExpanded[parentIndex]}
              setIsExpanded={() => toggleExpansion(parentIndex)} // Toggle the specific parent checkbox
            />
          </div>
          {isExpanded[parentIndex] && (
            <div className="child-checkboxes flex flex-col gap-4">
              {parent.children.map((child, childIndex) => (
                <div key={child.label}>
                  <CustomCheckBox
                    label={child.label}
                    checked={child.checked}
                    onChange={() => handleChildChange(parentIndex, childIndex)}
                    elementsNumber={elementsNumber(child.label)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterOptionsSection;
